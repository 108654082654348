import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createSlots as _createSlots, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = {
  style: {
    "color": "white"
  }
};
const _hoisted_2 = {
  key: 0,
  class: "eventDetailPanelTitle"
};
const _hoisted_3 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_4 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_5 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_6 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_7 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_8 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_9 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_10 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_11 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_12 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_13 = {
  class: "eventDetailPanelContentRequiredMark"
};
const _hoisted_14 = {
  class: "bothCenter"
};
const _hoisted_15 = ["src", "onLoadedmetadata"];
const _hoisted_16 = ["src"];
const _hoisted_17 = {
  key: 2,
  src: "https://cdn.waityou.online/90680d02-456a-7757-d26f-eae683dc61e3.svg",
  class: "el-upload-list__item-thumbnail"
};
const _hoisted_18 = {
  class: "el-upload-list__item-actions"
};
const _hoisted_19 = ["onClick"];
const _hoisted_20 = {
  style: {
    "color": "#CCCCCC"
  }
};
const _hoisted_21 = {
  key: 0
};
const _hoisted_22 = ["href"];
const _hoisted_23 = ["src"];
const _hoisted_24 = {
  style: {
    "width": "100%",
    "text-align": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_autocomplete = _resolveComponent("el-autocomplete");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_cascader = _resolveComponent("el-cascader");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_Plus = _resolveComponent("Plus");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_Delete = _resolveComponent("Delete");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.dialogVisible = $event),
    width: $data.detail.width ? $data.detail.width : '40%',
    onClose: $options.onDialogClose,
    "show-close": false
  }, {
    header: _withCtx(() => [_createVNode(_component_el_row, {
      class: "bg0"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 8
      }), _createVNode(_component_el_col, {
        span: 8,
        style: {
          "text-align": "center",
          "padding": "10px 0"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, _toDisplayString($data.detail.title), 1)]),
        _: 1
      }), _createVNode(_component_el_col, {
        span: 8,
        style: {
          "align-self": "center",
          "text-align": "right",
          "padding": "10px"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_svg_icon, {
          "icon-class": 'close',
          style: {
            "cursor": "pointer"
          },
          onClick: _cache[0] || (_cache[0] = $event => $data.dialogVisible = false)
        })]),
        _: 1
      })]),
      _: 1
    })]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.detail.details, (d, di) => {
      return _openBlock(), _createBlock(_component_el_row, {
        class: "eventDetailPanel",
        key: 'ed_' + $data.detail.id + '_' + di
      }, {
        default: _withCtx(() => [d.title ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_cache[2] || (_cache[2] = _createElementVNode("span", {
          style: {
            "background-color": "#015EF5"
          }
        }, " ", -1)), _createElementVNode("span", null, _toDisplayString(d.title), 1)])) : _createCommentVNode("", true), _createVNode(_component_el_row, {
          gutter: 10,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(d.items, (item, ii) => {
            return _openBlock(), _createBlock(_component_el_col, {
              span: d.width,
              key: di + '_' + ii,
              style: {
                "margin-bottom": "10px"
              }
            }, {
              default: _withCtx(() => [item.type === 'text' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label) + "：", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent",
                  style: _normalizeStyle(item.style)
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.value ? item.value : '-'), 1)]),
                  _: 2
                }, 1032, ["style"])]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'placeholder' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 1
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" ")])),
                  _: 1
                }), _createElementVNode("span", {
                  class: "eventDetailPanelContentItemContent",
                  style: _normalizeStyle(item.style)
                }, " ", 4)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'texts' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 2
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label) + "：", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.value, (t, i) => {
                    return _openBlock(), _createBlock(_component_el_tag, {
                      key: i,
                      size: "small",
                      style: {
                        "margin": "0 4px"
                      }
                    }, {
                      default: _withCtx(() => [_createTextVNode(_toDisplayString(t.text), 1)]),
                      _: 2
                    }, 1024);
                  }), 128))]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'input' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 3
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input, {
                    type: item.inputType ? item.inputType : 'text',
                    style: {
                      "width": "100%"
                    },
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    disabled: item.disabled,
                    placeholder: item.placeholder
                  }, _createSlots({
                    _: 2
                  }, [item.prefix ? {
                    name: "prepend",
                    fn: _withCtx(() => [_createTextVNode(_toDisplayString(item.prefix), 1)]),
                    key: "0"
                  } : undefined, item.suffix ? {
                    name: "append",
                    fn: _withCtx(() => [_createTextVNode(_toDisplayString(item.append), 1)]),
                    key: "1"
                  } : undefined]), 1032, ["type", "modelValue", "onUpdate:modelValue", "disabled", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'placeSearch' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 4
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_4, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_autocomplete, {
                    style: {
                      "width": "100%"
                    },
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    "fetch-suggestions": $options.queryPlaceAsync,
                    placeholder: item.placeholder,
                    onSelect: $options.handlePlaceSelect
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "fetch-suggestions", "placeholder", "onSelect"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'textarea' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 5
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_5, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input, {
                    type: "textarea",
                    style: {
                      "width": "100%"
                    },
                    maxlength: item.maxLength,
                    minlength: item.minLength,
                    "show-word-limit": item.maxLength || item.minLength,
                    rows: "5",
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    placeholder: item.placeholder
                  }, null, 8, ["maxlength", "minlength", "show-word-limit", "modelValue", "onUpdate:modelValue", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'datetime' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 6
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_6, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    style: {
                      "width": "100%"
                    },
                    type: "datetime",
                    "value-format": "YYYY-MM-DD HH:mm:ss",
                    placeholder: item.placeholder
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'datepicker' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 7
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_7, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_date_picker, {
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    "value-format": "YYYY-MM-DD",
                    style: {
                      "width": "100%"
                    },
                    type: item.pickerType,
                    placeholder: item.placeholder
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "type", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'select' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 8
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_8, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_select, {
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    disabled: item.disabled,
                    onChange: $event => $options.queryDataSource(item.name),
                    filterable: "",
                    class: "m-2",
                    placeholder: item.placeholder ? item.placeholder : '请选择'
                  }, {
                    default: _withCtx(() => [item.dataSource ? (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 0
                    }, _renderList($data.dataSource[item.name], op => {
                      return _openBlock(), _createBlock(_component_el_option, {
                        key: op.value,
                        label: op.label,
                        value: op.value
                      }, null, 8, ["label", "value"]);
                    }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 1
                    }, _renderList(item.value, op => {
                      return _openBlock(), _createBlock(_component_el_option, {
                        key: op.value,
                        label: op.label,
                        value: op.value
                      }, null, 8, ["label", "value"]);
                    }), 128))]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'radio' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 9
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_9, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_radio_group, {
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    disabled: item.disabled,
                    onChange: $event => $options.queryDataSource(item.name),
                    class: "m-2"
                  }, {
                    default: _withCtx(() => [item.dataSource ? (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 0
                    }, _renderList($data.dataSource[item.name], op => {
                      return _openBlock(), _createBlock(_component_el_radio, {
                        key: op.value,
                        label: op.value
                      }, {
                        default: _withCtx(() => [_createTextVNode(_toDisplayString(op.label), 1)]),
                        _: 2
                      }, 1032, ["label"]);
                    }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 1
                    }, _renderList(item.value, op => {
                      return _openBlock(), _createBlock(_component_el_radio, {
                        key: op.value,
                        label: op.value
                      }, {
                        default: _withCtx(() => [_createTextVNode(_toDisplayString(op.label), 1)]),
                        _: 2
                      }, 1032, ["label"]);
                    }), 128))]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'multiSelect' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 10
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_10, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_select, {
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    multiple: "",
                    disabled: item.disabled,
                    onChange: $event => $options.queryDataSource(item.name),
                    class: "m-2",
                    placeholder: item.placeholder ? item.placeholder : '请选择'
                  }, {
                    default: _withCtx(() => [item.dataSource ? (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 0
                    }, _renderList($data.dataSource[item.name], op => {
                      return _openBlock(), _createBlock(_component_el_option, {
                        key: op.value,
                        label: op.label,
                        value: op.value
                      }, null, 8, ["label", "value"]);
                    }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                      key: 1
                    }, _renderList(item.value, op => {
                      return _openBlock(), _createBlock(_component_el_option, {
                        key: op.value,
                        label: op.label,
                        value: op.value
                      }, null, 8, ["label", "value"]);
                    }), 128))]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "disabled", "onChange", "placeholder"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'cascader' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 11
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_11, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [item.dataSource ? (_openBlock(), _createBlock(_component_el_cascader, {
                    key: 0,
                    placeholder: item.placeholder ? item.placeholder : '请选择',
                    disabled: item.disabled,
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    onChange: v => {
                      this.form[item.name] = v;
                      $options.queryDataSource(item.name);
                    },
                    props: {
                      emitPath: false,
                      checkStrictly: item.checkStrictly,
                      lazy: true,
                      lazyLoad: (n, s) => $options.fnLazyLoad(item.name, item.dataSource, n, s)
                    },
                    "show-all-levels": false
                  }, null, 8, ["placeholder", "disabled", "modelValue", "onUpdate:modelValue", "onChange", "props"])) : (_openBlock(), _createBlock(_component_el_cascader, {
                    key: 1,
                    modelValue: $data.form[item.name],
                    "onUpdate:modelValue": $event => $data.form[item.name] = $event,
                    disabled: item.disabled,
                    placeholder: item.placeholder ? item.placeholder : '请选择',
                    onChange: $event => $options.queryDataSource(item.name),
                    props: {
                      emitPath: false
                    },
                    options: item.value,
                    "show-all-levels": false
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "placeholder", "onChange", "options"]))]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'tree' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 12
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_12, _toDisplayString(item.required ? '*' : ''), 1), _createTextVNode(" " + _toDisplayString(item.label) + "： ", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemContent"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_tree, {
                    style: {
                      "border": "#EEEEEE solid 2px",
                      "width": "300px"
                    },
                    "show-checkbox": item.changeable,
                    "node-key": item.key,
                    data: item.value,
                    "highlight-current": "",
                    props: item.props,
                    "default-expand-all": item.expand,
                    "default-checked-keys": $data.form[item.name],
                    onCheck: $data.eventActions[item.name]
                  }, null, 8, ["show-checkbox", "node-key", "data", "props", "default-expand-all", "default-checked-keys", "onCheck"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'uploadImage' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 13
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("span", _hoisted_13, _toDisplayString(item.required ? '* ' : ' '), 1), _createElementVNode("span", null, _toDisplayString(item.label) + "(" + _toDisplayString(item.limit) + "份)：", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_upload, {
                    "list-type": "picture-card",
                    "auto-upload": true,
                    limit: item.limit,
                    id: item.name,
                    "before-upload": f => $options.beforeImageUpload(f, item.suffixLimit),
                    "http-request": $options.uploadRequest,
                    "file-list": $data.form[item.name + '_init'],
                    "on-success": r => $options.handleImageUploadSuccess(item.name, r.url, r, item.limit),
                    "on-remove": d => $options.handleUploadImageRemove(item.name, d)
                  }, {
                    file: _withCtx(({
                      file
                    }) => [_createElementVNode("div", _hoisted_14, [$options.isMp4(file) ? (_openBlock(), _createElementBlock("video", {
                      key: 0,
                      src: file.url,
                      controls: "",
                      onLoadedmetadata: e => $options.onLoadedVideoMetadata(e, file),
                      style: {
                        "width": "100%"
                      }
                    }, null, 40, _hoisted_15)) : $options.isImage(file) ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: file.url,
                      class: "el-upload-list__item-thumbnail"
                    }, null, 8, _hoisted_16)) : (_openBlock(), _createElementBlock("img", _hoisted_17)), _createElementVNode("span", _hoisted_18, [_createElementVNode("span", {
                      class: "el-upload-list__item-delete",
                      onClick: $event => $options.handleRemove(file, item.name)
                    }, [_createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [_createVNode(_component_Delete)]),
                      _: 1
                    })], 8, _hoisted_19)])])]),
                    default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                      default: _withCtx(() => [_createVNode(_component_Plus)]),
                      _: 1
                    })]),
                    _: 2
                  }, 1032, ["limit", "id", "before-upload", "http-request", "file-list", "on-success", "on-remove"]), _createElementVNode("span", _hoisted_20, _toDisplayString(item.suffixLimit ? `仅支持${item.suffixLimit.join('、')}格式` : '支持.jpg、.png、.gif、.mp4、.zip等常见格式'), 1), $data.uploading ? (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[4] || (_cache[4] = [_createElementVNode("span", {
                    style: {
                      "color": "red"
                    }
                  }, "上传中...请稍等", -1)]))) : _createCommentVNode("", true)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'link' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 14
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label) + "：", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18,
                  class: "eventDetailPanelContentItemLink"
                }, {
                  default: _withCtx(() => [_createElementVNode("a", {
                    href: item.link,
                    target: "_blank"
                  }, _toDisplayString(item.value), 9, _hoisted_22)]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true), item.type === 'images' ? (_openBlock(), _createBlock(_component_el_row, {
                key: 15
              }, {
                default: _withCtx(() => [_createVNode(_component_el_col, {
                  span: 6,
                  class: "eventDetailPanelContentItemTitle"
                }, {
                  default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.label) + "：", 1)]),
                  _: 2
                }, 1024), _createVNode(_component_el_col, {
                  span: 18
                }, {
                  default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.images, (img, imgIdx) => {
                    return _openBlock(), _createElementBlock("img", {
                      key: imgIdx,
                      src: img,
                      class: "eventDetailPanelContentImg"
                    }, null, 8, _hoisted_23);
                  }), 128))]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true)]),
              _: 2
            }, 1032, ["span"]);
          }), 128))]),
          _: 2
        }, 1024)]),
        _: 2
      }, 1024);
    }), 128)), _renderSlot(_ctx.$slots, "custom", {
      rowData: $data.detail
    }, undefined, true), _createElementVNode("div", _hoisted_24, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.detail.buttons, (b, i) => {
      return _openBlock(), _createBlock(_component_el_button, {
        onClick: $event => $options.onButtonClick(b.action),
        key: i,
        style: _normalizeStyle(b.style)
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(b.text), 1)]),
        _: 2
      }, 1032, ["onClick", "style"]);
    }), 128))])]),
    _: 3
  }, 8, ["modelValue", "width", "onClose"]);
}
import {createStore} from 'vuex'
export default createStore({
    modules: {

    },
    state: {
        user: {},
    },
    getters: {},
    mutations: {
        updateUser(state, val) {
            state.user = val;
        },
    },
    actions: {},
})

import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
import md5 from "js-md5";
export default {
  name: "LoginPage",
  data() {
    return {
      loginMode: 1,
      loginModel: {
        token: null,
        account: null,
        captcha: null,
        password: null
      },
      passwordError: null,
      accountError: null
    };
  },
  methods: {
    ...mapMutations(['updateUser']),
    goNext(ret) {
      // 取到原页面路径
      let url = this.$route.query.redirect;
      // 跳转回原页面
      this.$router.push({
        path: decodeURIComponent(url ? url : ret.index)
      });
    },
    doLogin() {
      let verify = true;
      if (!this.loginModel.account) {
        this.accountError = '* 账号必填';
        verify = false;
      }
      if (!this.loginModel.password) {
        this.passwordError = '* 密码必填';
        verify = false;
      }
      if (!verify) {
        return;
      }
      if (this.demoMode) {
        localStorage.setItem('access_token', '-');
        // 取到原页面路径
        let url = this.$route.query.redirect;
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(url ? url : '/s/dashboard')
        });
        return;
      }
      this.accountError = null;
      this.passwordError = null;
      api.login({
        phone: this.loginModel.account,
        password: md5(this.loginModel.password),
        token: this.loginModel.token,
        captcha: this.loginModel.captcha,
        channel: 0
      }).then(ret => {
        localStorage.setItem('access_token', ret.accessToken);
        this.updateUser(ret);
        this.goNext(ret);
      }).catch(err => {
        console.log('login err', err);
        this.init();
      });
    },
    init() {
      api.getLoginCaptchaToken().then(ret => {
        this.loginModel.token = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};
import "core-js/modules/es.array.push.js";
import api from "@/api";
import MenuItem from "@/components/MenuItem";
export default {
  name: "TopMenuII",
  components: {
    MenuItem
  },
  data() {
    return {
      menus: [],
      firstLine: [],
      popups: [],
      more: false,
      hasMoreIcon: false,
      screenWidth: 0,
      enterPopover: false
    };
  },
  methods: {
    onPopoverEnter() {
      console.log('enterPopover=true');
      this.enterPopover = true;
    },
    init() {
      this.screenWidth = this.$refs.firstRow.offsetWidth;
      api.getMenus().then(ret => {
        this.menus = ret;
        let maxSize = parseInt(this.screenWidth / 80);
        this.firstLine = ret.slice(0, maxSize);
        this.popups = ret.slice(maxSize, ret.length);
        this.hasMoreIcon = maxSize < ret.length;
      });
    },
    goto(link) {
      if (!link) {
        return;
      }
      this.$router.push(link);
    },
    onClick(e) {
      if (!this.more) {
        return;
      }
      const elementToCheck = this.$refs.moreMenu;
      const rect = elementToCheck.getBoundingClientRect();
      const isClickedInside = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY <= rect.bottom;
      if (!isClickedInside) {
        this.more = false;
      }
    }
  },
  mounted() {
    this.init();
    document.addEventListener('click', this.onClick);
  },
  unmounted() {
    document.removeEventListener('click', this.onClick);
  }
};
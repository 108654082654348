const base = {
    baseUrl: "",
    menus: "/api/manager/menu/menuList",
    userInfo: "/api/manager/info/my",
    loginCaptchaToken: "/login/captchaToken",
    login: "/login/accountLogin",
    logout: "/api/user/logout?channel=0",

    getBannerList: "/api/manager/banner/list",
    createBanner: "/api/manager/setting/mobile/createBanner",
    updateBanner: "/api/manager/setting/mobile/updateBanner",
    deleteBanner: "/api/manager/setting/mobile/delete?bannerId={0}",

    getOssKeySecret: "/api/manager/oss/getOssKeySecret",
    updateSystemConfig: '/api/manager/config/update',
    getSystemConfig: '/api/manager/config/list',
    createRole: '/api/manager/role/create',
    updateUserEnableState: '/api/manager/user/updateEnableState?id={0}&value={1}',
    updateUserManagerLogin: '/api/manager/user/updateUserManagerLogin?id={0}&value={1}',
    createEnumValue: '/api/manager/enum/create',
    updateEnumValueEnableState: '/api/manager/enum/updateEnumValueEnableState?id={0}&value={1}',
    getEnumOptionList: '/api/manager/enum/list4select/{0}',
    updateBannerEnableState: '/api/manager/setting/mobile/updateBannerEnableState?id={0}&value={1}',

    getConfigValues: '/api/manager/config/getConfigValues?keys={0}',
    queryBusList: '/api/manager/bus/getBusList',
    deleteBus: '/api/manager/bus/deleteBus?id={0}',
    deleteAllMergedInfo: '/api/manager/bus/merge/deleteAllMergedInfo',
    batchDeletedMerged: '/api/manager/bus/merge/batchDelete',


    queryForm: {
        1: '/api/manager/bus/getQueryForm',
        2: '/api/manager/user/getQueryForm',
        3: '/api/manager/bus/call/getQueryForm',
        4: '/api/manager/bus/merge/getQueryForm',
        5: '/api/manager/login/record/getQueryForm',
    },
    dialogs: {
        1: '/api/manager/bus/getBusInfoCreateForm',
        2: '/api/manager/bus/getBusInfoEditForm?id={0}',
        3: '/api/manager/role/getCreateForm',
        4: '/api/manager/role/getEditForm?roleId={0}',
        5: '/api/manager/role/getCreateForm',
        6: '/api/manager/user/getCreateForm',
        7: '/api/manager/user/getEditForm?userId={0}',
        8: '/api/manager/user/detail?userId={0}',
        9: '/api/manager/enum/getCreateForm?type={0}',
        10: '/api/manager/enum/getUpdateForm?id={0}',
        11: '/api/manager/bus/getPassengerImportForm',
        12: '/api/manager/bus/getBusPassengerEditForm?id={0}',
        13: '/api/manager/bus/merge/getMergeImportForm',
        14: '/api/manager/bus/merge/getBusPassengerEditForm?id={0}',
    },
    simpleBtn: {
        1: {
            dialogId: 1,
            submitUrl: '/api/manager/bus/createOrUpdateBus',
        },
        2: {
            dialogId: 5,
            submitUrl: '/api/manager/role/create'
        },
        3: {
            dialogId: 2,
            submitUrl: '/api/manager/bus/createOrUpdateBus',
        },
        4: {
            dialogId: 11,
            submitUrl: '/api/manager/bus/importPassenger',
        },
        5: {
            dialogId: 13,
            submitUrl: '/api/manager/bus/merge/mergeImport',
        },
    },
    table: {
        1: {
            queryForm: 1,
            list: '/api/manager/organization/list',
            createDialog: 2,
            create: '/api/manager/organization/create',
            updateDialog: 1,
            update: '/api/manager/organization/update',
            updateKey: 'id',
            delete: '/api/manager/organization/delete',
            deleteKey: 'id'
        },
        4: {
            list: '/api/manager/role/list',
            updateDialog: 4,
            updateKey: 'roleId',
            update: '/api/manager/role/update',
            delete: '/api/manager/role/delete',
            deleteKey: 'roleId',
        },
        5: {
            queryForm: 2,
            list: '/api/manager/user/list',
            createDialog: 6,
            create: '/api/manager/user/create',
            updateDialog: 7,
            update: '/api/manager/user/update',
            updateKey: 'id',
            delete: '/api/manager/user/delete',
            deleteKey: 'id',
            detailDialog: 8,
            detailKey: 'userId'
        },
        6: {
            queryForm: 2,
            list: '/api/manager/user/list',
            createDialog: 6,
            create: '/api/manager/user/create',
            updateDialog: 7,
            update: '/api/manager/user/update',
            updateKey: 'id',
            delete: '/api/manager/user/delete',
            deleteKey: 'id',
            detailDialog: 8,
            detailKey: 'userId'
        },
        7: {
            queryForm: 1,
            list: '/api/manager/bus/list',
            delete: '/api/manager/bus/deletePassenger',
            deleteKey: 'id',
            updateDialog: 12,
            update: '/api/manager/bus/updatePassenger',
            updateKey: 'id',
        },
        8: {
            queryForm: 5,
            list: '/api/manager/login/record/list',
            export: '/api/manager/login/record/export',
        },
        9: {
            queryForm: 3,
            list: '/api/manager/bus/call/list',
        },
        10: {
            queryForm: 4,
            list: '/api/manager/bus/merge/list',
            delete: '/api/manager/bus/merge/delete',
            deleteKey: 'id',
            updateDialog: 14,
            update: '/api/manager/bus/merge/update',
            updateKey: 'id',
        },
    }

}

export default base;
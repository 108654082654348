import BatterTableDataView from "@/components/BatterTableDataView.vue";
export default {
  name: "CallRecordListView",
  components: {
    BatterTableDataView
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};
import api from "@/api";
import TopMenuII from "@/components/TopMenuII";
import HeaderTabs from "@/components/HeaderTabs";
export default {
  name: "TopHomeView",
  // eslint-disable-next-line vue/no-unused-components
  components: {
    HeaderTabs,
    TopMenuII
  },
  methods: {
    init() {
      api.getUserInfo(localStorage.getItem('access_token')).then(ret => {
        this.$store.commit('updateUser', ret);
      });
    },
    logout() {
      localStorage.removeItem('access_token');
      this.$router.go(0);
    }
  },
  mounted() {
    this.init();
  }
};
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "background-color": "white"
  }
};
const _hoisted_2 = {
  class: "pageTitle padding20"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($data.title) + "管理", 1), _createVNode(_component_el_divider, {
      class: "padding0 margin0"
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "pd12"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      class: "primaryBtn",
      icon: 'plus',
      onClick: _cache[0] || (_cache[0] = $event => {
        $data.createVisible = true;
      })
    }, {
      default: _withCtx(() => [_createTextVNode("添加" + _toDisplayString($data.title), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "pd12"
  }, {
    default: _withCtx(() => [(_openBlock(), _createBlock(_component_BatterTableDataView, {
      "table-id": "4",
      "init-query": $data.initQuery,
      key: $data.type,
      ref: "enumValueTable",
      onOnFetchData: $options.onFetchData
    }, {
      enable: _withCtx(data => [_createVNode(_component_el_switch, {
        "model-value": $data.switches[data.rowId],
        onChange: val => $options.updateEnableState(data.rowId, val),
        class: "ml-2",
        style: {
          "--el-switch-on-color": "#015EF5",
          "--el-switch-off-color": "lightgray"
        }
      }, null, 8, ["model-value", "onChange"])]),
      _: 1
    }, 8, ["init-query", "onOnFetchData"]))]),
    _: 1
  })]), _createVNode(_component_DialogView, {
    "visible-control": $data.createVisible,
    "data-id": 9,
    "bind-id": $data.type,
    onOnDialogClose: _cache[1] || (_cache[1] = $event => $data.createVisible = false),
    onSubmit: $options.createEnumValue
  }, null, 8, ["visible-control", "bind-id", "onSubmit"])], 64);
}
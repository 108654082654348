import ButtonDialog from "@/components/ButtonDialog.vue";
import BatterTableDataView from "@/components/BatterTableDataView.vue";
import api from "@/api";
import { ElLoading } from "element-plus";
export default {
  name: "MergeModeView",
  components: {
    BatterTableDataView,
    ButtonDialog
  },
  data() {
    return {
      batchSelected: []
    };
  },
  methods: {
    batchDelete() {
      let loading = ElLoading.service({
        lock: true,
        text: '删除中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.batchDeleteMerged({
        uids: this.batchSelected
      }).then(() => {
        loading.close();
        this.$refs.ptable.fetchData();
      });
    },
    onRowSelectChange(rows) {
      this.batchSelected = rows.map(r => r.id);
    },
    deleteAll() {
      let loading = ElLoading.service({
        lock: true,
        text: '删除中...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      api.deleteAllMergedInfo().then(() => {
        loading.close();
        this.$refs.ptable.fetchData();
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "IndexViewContainer bothCenter colFlex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [_createElementVNode("span", {
    class: "ft30"
  }, "Powered By 冰海智能服务", -1), _createElementVNode("img", {
    src: "https://cdn.waityou.online/c6fcc574-76a6-61c9-7370-daaa05d734e4.svg",
    class: "mt30"
  }, null, -1)]));
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "BusIndexViewContainer pd16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonDialog = _resolveComponent("ButtonDialog");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BusPassengerView = _resolveComponent("BusPassengerView");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "mb16"
  }, {
    default: _withCtx(() => [_createVNode(_component_ButtonDialog, {
      "btn-id": 1,
      ref: "createDialog",
      "btn-name": "新增车次",
      onFresh: $options.init
    }, null, 8, ["onFresh"])]),
    _: 1
  }), _createVNode(_component_el_tabs, {
    type: "border-card",
    modelValue: $data.tabIdx,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.tabIdx = $event)
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.busList, (b, bi) => {
      return _openBlock(), _createBlock(_component_el_tab_pane, {
        key: `bus_${b}`,
        name: bi,
        label: b.busName + '-' + b.busIndex
      }, {
        default: _withCtx(() => [_createVNode(_component_BusPassengerView, {
          bus: b,
          onFresh: $options.init
        }, null, 8, ["bus", "onFresh"])]),
        _: 2
      }, 1032, ["name", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"])]);
}
import ButtonDialog from "@/components/ButtonDialog.vue";
import api from "@/api";
import BusPassengerView from "@/views/bus/BusPassengerView.vue";
export default {
  name: "BusIndexView",
  components: {
    BusPassengerView,
    ButtonDialog
  },
  data() {
    return {
      tabIdx: 0,
      busList: []
    };
  },
  methods: {
    addBus() {
      this.$refs.createDialog.open();
    },
    init() {
      api.queryBusList().then(ret => {
        if (ret.length <= this.tabIdx) {
          this.tabIdx = 0;
        }
        this.busList = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  class: "ButtonDialogContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [$props.slotBtn ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = $event => $data.btnVisible = true)
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])) : (_openBlock(), _createBlock(_component_el_button, {
    key: 1,
    type: "primary",
    style: _normalizeStyle({
      'width': $props.width
    }),
    onClick: _cache[1] || (_cache[1] = $event => $data.btnVisible = true)
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.btnName), 1)]),
    _: 1
  }, 8, ["style"]))]), _createVNode(_component_DialogView, {
    "data-id": $data.dialogId,
    "bind-id": $props.bindId,
    onSubmit: $options.onSubmit,
    onClose: _cache[2] || (_cache[2] = $event => $data.btnVisible = false),
    "visible-control": $data.btnVisible
  }, null, 8, ["data-id", "bind-id", "onSubmit", "visible-control"])], 64);
}
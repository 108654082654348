import DialogView from "@/components/DialogView";
import path from "@/api/path";
import api from "@/api";
export default {
  name: "ButtonDialog",
  components: {
    DialogView
  },
  props: {
    bindId: {
      type: Number,
      default: null
    },
    btnId: {
      type: Number,
      default: 0
    },
    slotBtn: {
      type: Boolean,
      default: false
    },
    btnName: {
      type: String,
      default: '新增'
    },
    width: {
      type: String,
      default: '100px'
    },
    initData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dialogId: 0,
      submitUrl: '',
      btnVisible: false
    };
  },
  methods: {
    open() {
      this.btnVisible = true;
    },
    onSubmit(d) {
      for (let k of Object.keys(this.initData)) {
        d[k] = this.initData[k];
      }
      api.post(this.submitUrl, d).then(() => {
        this.btnVisible = false;
        this.$emit('fresh');
      });
    },
    init() {
      this.dialogId = path.simpleBtn[this.btnId].dialogId;
      this.submitUrl = path.simpleBtn[this.btnId].submitUrl;
    }
  },
  mounted() {
    this.init();
  }
};
import {createRouter, createWebHashHistory} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import UserRoleListView from "@/views/user/UserRoleListView";
import UserListView from "@/views/user/UserListView";
import EnumValueListView from "@/views/system/EnumValueListView";
import BannerSettingView from "@/views/banner/BannerSettingView";
import TopHomeView from "@/views/TopHomeView";
import IndexView from "@/views/IndexView.vue";
import BusIndexView from "@/views/bus/BusIndexView.vue";
import LoginRecordList from "@/views/system/LoginRecordList.vue";
import CallRecordListView from "@/views/bus/CallRecordListView.vue";
import MergeModeView from "@/views/bus/MergeModeView.vue";


const routes = [
    {
        path: "/",
        name: "home",
        component: TopHomeView,
        redirect: "/index",
        children: [
            {
                path: "/index",
                component: IndexView
            }, {
                path: '/user/role/list',
                component: UserRoleListView
            },{
                path: '/user/list',
                component: UserListView
            }, {
                path: '/setting/banner',
                component: BannerSettingView
            }, {
                path: '/enum/list/:type',
                component: EnumValueListView
            },{
                path: '/bus/list',
                component: BusIndexView
            },{
                path: '/bus/v2',
                component: MergeModeView
            },{
                path: '/bus/call/list',
                component: CallRecordListView
            },{
                path: '/login/record',
                component: LoginRecordList
            },
        ],
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            auth: false
        }
    },

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "TopHomeViewContainer"
};
const _hoisted_2 = {
  style: {
    "width": "calc(100% - 310px)"
  }
};
const _hoisted_3 = {
  class: "mr12"
};
const _hoisted_4 = {
  class: "pd10 colFlex"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TopMenuII = _resolveComponent("TopMenuII");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bg0 wft opd16 vpd8",
    style: {
      "box-sizing": "border-box"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      style: {
        "width": "calc(100% - 220px)"
      },
      class: "leftCenter"
    }, {
      default: _withCtx(() => [_cache[1] || (_cache[1] = _createElementVNode("span", {
        class: "ft22 boldFont mr16"
      }, "Tang's App Platform", -1)), _createElementVNode("div", _hoisted_2, [_createVNode(_component_TopMenuII)])]),
      _: 1
    }), _createVNode(_component_el_row, {
      style: {
        "width": "220px"
      },
      class: "rightCenter ft14 wft"
    }, {
      default: _withCtx(() => [_createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$store.state.user.userName), 1), _createVNode(_component_el_avatar, {
        size: 30,
        "src-set": _ctx.$store.state.user.avatar,
        class: "brd4 mr12"
      }, null, 8, ["src-set"]), _createElementVNode("span", {
        class: "wft ptr",
        onClick: _cache[0] || (_cache[0] = (...args) => $options.logout && $options.logout(...args))
      }, "退出")]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_view)])]);
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "wbg colFlex pd16",
  style: {
    "min-height": "calc(100vh - 115px)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonDialog = _resolveComponent("ButtonDialog");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "mb16"
  }, {
    default: _withCtx(() => [_createVNode(_component_ButtonDialog, {
      "btn-name": "新增角色",
      "btn-id": "2",
      onFresh: _cache[0] || (_cache[0] = $event => _ctx.$refs.roleTable.fetchData())
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_BatterTableDataView, {
      "table-id": "4",
      ref: "roleTable"
    }, null, 512)]),
    _: 1
  })]);
}
import axios from "../utils/http";
import path from "./path"

const stringFormat = (formatted, args) => {
    for (let i = 0; i < args.length; i++) {
        let regexp = new RegExp('\\{' + i + '\\}', 'gi')
        formatted = formatted.replace(regexp, args[i])
    }
    return formatted
}
const api = {
    getOssKeySecret() {
        return axios.get(path.baseUrl + path.getOssKeySecret)
    },
    getQueryForm(formId, query) {
        return axios.get(path.baseUrl + path.queryForm[formId], {params: query})
    },
    get(url, query) {
        if (query) {
            return axios.get(path.baseUrl + url, {params: query})
        }
        return axios.get(path.baseUrl + url)
    },
    post(url, data) {
        return axios.post(path.baseUrl + url, data)
    },
    getDialogForm(dataId, bindId) {
        if (bindId != null) {
            return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
        }
        return axios.get(path.baseUrl + path.dialogs[dataId])
    },
    getMenus() {
        return axios.get(path.baseUrl + path.menus)
    },
    getUserInfo(accessToken) {
        console.log('getUserInfo accessToken', accessToken);
        return axios.get(path.baseUrl + path.userInfo)
    },
    getLoginCaptchaToken() {
        return axios.get(path.baseUrl + path.loginCaptchaToken)
    },
    login(data) {
        return axios.post(path.baseUrl + path.login, data)
    },
    logout() {
        return axios.get(path.baseUrl + path.logout)
    },
    getBannerList(query) {
        return axios.get(path.baseUrl + path.getBannerList, {params: query})
    },
    createBanner(data) {
        return axios.post(path.baseUrl + path.createBanner, data);
    },
    updateBanner(data) {
        return axios.post(path.baseUrl + path.updateBanner, data);
    },
    deleteBanner(bannerId) {
        return axios.get(path.baseUrl + stringFormat(path.deleteBanner, [bannerId]))
    },

    updateSystemConfig(data) {
        return axios.post(path.baseUrl + path.updateSystemConfig, data);
    },
    getSystemConfig() {
        return axios.get(path.baseUrl + path.getSystemConfig)
    },
    updateUserEnableState(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserEnableState, [id, val]))
    },
    updateUserManagerLogin(id, val) {
        return axios.get(path.baseUrl + stringFormat(path.updateUserManagerLogin, [id, val]))
    },
    queryBusList() {
        return axios.get(path.baseUrl + path.queryBusList)
    },
    deleteBus(id) {
        return axios.get(path.baseUrl + stringFormat(path.deleteBus, [id]))
    },
    deleteAllMergedInfo() {
        return axios.get(path.baseUrl + path.deleteAllMergedInfo)
    },
    batchDeleteMerged(data) {
        return axios.post(path.baseUrl + path.batchDeletedMerged, data);
    }
}

export default api;
import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "MenuItemContainer bothCenter ptr opd18"
};
const _hoisted_3 = {
  key: 1,
  class: "MenuItemContainer bothCenter ptr opd18"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_CaretBottom = _resolveComponent("CaretBottom");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_popover = _resolveComponent("el-popover");
  return $props.data.subMenus ? (_openBlock(), _createBlock(_component_el_popover, {
    key: 0,
    trigger: "click",
    "show-arrow": false,
    effect: "dark",
    placement: "bottom",
    width: "100px",
    "hide-after": 100,
    offset: 2,
    "popper-style": {
      padding: 0,
      border: 'none',
      textAlign: 'center',
      boxShadow: '0px 1px 2px 0px #000000',
      background: '#000000'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      class: "colFlex wd100 vpd8"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.subMenus, s => {
        return _openBlock(), _createElementBlock("span", {
          class: _normalizeClass({
            'menuText bg0 wft ptr vpd vpd8': $data.activeId == s.id,
            'menuText bg0 lightText ptr vpd8': $data.activeId != s.id
          }),
          key: s.id,
          onClick: $event => _ctx.$router.push(s.path)
        }, _toDisplayString(s.name), 11, _hoisted_1);
      }), 128))]),
      _: 1
    })]),
    reference: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
      class: _normalizeClass({
        'wft': $data.active
      })
    }, _toDisplayString($props.data.name), 3), _createVNode(_component_el_icon, {
      color: $data.active ? 'white' : ''
    }, {
      default: _withCtx(() => [_createVNode(_component_CaretBottom)]),
      _: 1
    }, 8, ["color"])])]),
    _: 1
  })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("span", {
    class: _normalizeClass({
      'wft': $data.active
    }),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onClick && $options.onClick(...args))
  }, _toDisplayString($props.data.name), 3)]));
}
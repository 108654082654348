import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "UserRoleListView",
  components: {
    ButtonDialog,
    BatterTableDataView
  },
  data() {
    return {
      crumb: [{
        name: '角色管理'
      }]
    };
  },
  methods: {}
};
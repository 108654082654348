import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "BusPassengerViewContainer"
};
const _hoisted_2 = {
  class: "mr16",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_3 = {
  class: "mr16",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_4 = {
  class: "mr16",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_5 = {
  class: "mr16",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_6 = {
  class: "mr16",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_7 = {
  class: "mr32",
  style: {
    "color": "#009FFF"
  }
};
const _hoisted_8 = {
  class: "mr32"
};
const _hoisted_9 = {
  class: "mr32"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonDialog = _resolveComponent("ButtonDialog");
  const _component_el_popconfirm = _resolveComponent("el-popconfirm");
  const _component_el_row = _resolveComponent("el-row");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "leftCenter mb16"
  }, {
    default: _withCtx(() => [_cache[3] || (_cache[3] = _createElementVNode("span", null, "班次：", -1)), _createElementVNode("span", _hoisted_2, _toDisplayString($props.bus.busName), 1), _cache[4] || (_cache[4] = _createElementVNode("span", {
      class: ""
    }, "车次：", -1)), _createElementVNode("span", _hoisted_3, _toDisplayString($props.bus.busIndex), 1), _cache[5] || (_cache[5] = _createElementVNode("span", {
      class: ""
    }, "司机：", -1)), _createElementVNode("span", _hoisted_4, _toDisplayString($props.bus.driverName), 1), _cache[6] || (_cache[6] = _createElementVNode("span", {
      class: ""
    }, "司机手机号：", -1)), _createElementVNode("span", _hoisted_5, _toDisplayString($props.bus.driverPhone), 1), _cache[7] || (_cache[7] = _createElementVNode("span", {
      class: ""
    }, "随车负责人：", -1)), _createElementVNode("span", _hoisted_6, _toDisplayString($props.bus.leaderName), 1), _cache[8] || (_cache[8] = _createElementVNode("span", null, "随车负责人手机号：", -1)), _createElementVNode("span", _hoisted_7, _toDisplayString($props.bus.leaderPhone), 1), _createElementVNode("div", _hoisted_8, [_createVNode(_component_ButtonDialog, {
      "btn-name": "编辑",
      onFresh: _cache[0] || (_cache[0] = $event => _ctx.$emit('fresh')),
      "init-data": {
        id: $props.bus.id
      },
      "btn-id": 3,
      "bind-id": $props.bus.id
    }, null, 8, ["init-data", "bind-id"])]), _createElementVNode("div", _hoisted_9, [_createVNode(_component_ButtonDialog, {
      "btn-name": "导入乘客",
      "init-data": {
        busId: $props.bus.id
      },
      onFresh: _cache[1] || (_cache[1] = $event => _ctx.$refs.ptable.fetchData()),
      "btn-id": 4,
      "bind-id": $props.bus.id
    }, null, 8, ["init-data", "bind-id"])]), _cache[9] || (_cache[9] = _createElementVNode("a", {
      target: "_blank",
      href: "https://smarket.oss-cn-beijing.aliyuncs.com/2024/07/18/86d9302f-bee5-4da4-be22-1063087d393f.xlsx"
    }, "下载导入模板", -1)), _createVNode(_component_el_popconfirm, {
      title: "确定删除吗",
      onConfirm: $options.deleteBus
    }, {
      reference: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("span", {
        style: {
          "cursor": "pointer",
          "margin-left": "32px",
          "color": "#F4333C"
        }
      }, "删除班次", -1)])),
      _: 1
    }, 8, ["onConfirm"])]),
    _: 1
  }), _createVNode(_component_BatterTableDataView, {
    "table-id": 7,
    "init-query": {
      busId: $props.bus.id
    },
    ref: "ptable"
  }, {
    state: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    _: 1
  }, 8, ["init-query"])]);
}
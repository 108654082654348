import ButtonDialog from "@/components/ButtonDialog.vue";
import BatterTableDataView from "@/components/BatterTableDataView.vue";
import api from "@/api";
export default {
  name: "BusPassengerView",
  components: {
    BatterTableDataView,
    ButtonDialog
  },
  props: {
    bus: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    deleteBus() {
      api.deleteBus(this.bus.id).then(() => {
        this.$emit('fresh');
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};
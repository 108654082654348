import "core-js/modules/es.array.push.js";
import api from "@/api";
export default {
  name: "MenuItem",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      active: false,
      activeId: 0
    };
  },
  watch: {
    $route() {
      this.init();
    }
  },
  methods: {
    onClick() {
      if (this.data.path.startsWith('http')) {
        window.open(this.data.path, '_blank');
        return;
      } else if (this.data.path.startsWith('YUNLU_DASHBOARD')) {
        api.getYunluDashboardUrl().then(ret => {
          window.location.href = ret;
        });
        return;
      }
      this.$router.push(this.data.path);
    },
    init() {
      this.activeId = 0;
      this.active = false;
      let path = this.$route.path;
      if (this.data.path && path.startsWith(this.data.path)) {
        this.active = true;
        return;
      }
      if (!this.data.subMenus) {
        return;
      }
      for (let s of this.data.subMenus) {
        if (s.path && path.startsWith(s.path)) {
          this.active = true;
          this.activeId = s.id;
          return;
        }
      }
    }
  },
  mounted() {
    this.init();
  }
};
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  style: {
    "width": "calc(100% - 22px)"
  },
  class: "leftCenter",
  ref: "firstRow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MenuItem = _resolveComponent("MenuItem");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_row, {
    class: "TopMenuIIContainer leftCenter ft16"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.firstLine, m => {
      return _openBlock(), _createElementBlock("div", {
        key: m.id
      }, [_createVNode(_component_MenuItem, {
        data: m
      }, null, 8, ["data"])]);
    }), 128))], 512), $data.hasMoreIcon ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [$data.more ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: "https://cdn.waityou.online/438927f5-7804-e5f0-07cb-94899639b016.svg",
      onClick: _cache[0] || (_cache[0] = $event => $data.more = false),
      class: "sqr22 ptr"
    })) : (_openBlock(), _createElementBlock("img", {
      key: 1,
      src: "https://cdn.waityou.online/b6cc7c1d-c937-3a92-7c7e-039245cffd36.svg",
      onClick: _cache[1] || (_cache[1] = $event => $data.more = true),
      ref: "moreBtn",
      class: "sqr22 ptr"
    }, null, 512))], 64)) : _createCommentVNode("", true)]),
    _: 1
  }), $data.more ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    style: _normalizeStyle([{
      "flex-wrap": "wrap"
    }, {
      width: `${$data.screenWidth + 24}px`,
      zIndex: 99,
      position: 'absolute',
      top: '30px',
      left: '230px',
      borderRadius: '0 0 8px 8px'
    }]),
    ref: "moreMenu",
    class: "leftCenter bg0 vpd4"
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.popups, m => {
    return _openBlock(), _createElementBlock("div", {
      class: "menuItem vpd4",
      key: m.id
    }, [_createVNode(_component_MenuItem, {
      data: m
    }, null, 8, ["data"])]);
  }), 128))], 4)) : _createCommentVNode("", true)], 64);
}
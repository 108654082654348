import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "CallRecordListViewContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 9
  }, {
    talked: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.rowData ? '✅' : '❌'), 1)]),
    _: 1
  })]);
}
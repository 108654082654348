import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "pd16 box"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 5,
    ref: "userTable",
    "init-query": $data.initQuery,
    onOnFetchData: $options.onFetchData
  }, {
    avatar: _withCtx(data => [_createElementVNode("img", {
      src: data.rowData,
      style: {
        "width": "50px"
      }
    }, null, 8, _hoisted_2)]),
    state: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    _: 1
  }, 8, ["init-query", "onOnFetchData"])]);
}